<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="questionnaire" service="credit" backlink="/credit/price" />
      <div class="content">
        <div class="content__container">
          <p class="promo promo_blocks2">{{ 'question_10' | localize }}</p>
          <div class="service__container">
            <button
              type="button"
              class="service service_question service_question_1"
              @click="saveAndGo(1)"
            >
              <div class="service__title">{{ "yes" | localize }}</div>
            </button>
            <button
              type="button"
              class="service service_question service_question_2"
              @click="saveAndGo(0)"
            >
              <div class="service__title">{{ "no" | localize }}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Credit_have_mortgage',
  components: { Backlink },
  mixins: [commonMixin],
  methods: {
    saveAndGo (value) {
      const credit = this.$cookies.get('credit') || {}
      credit.mortgage = value
      this.$cookies.set('credit', credit)

      // Сброс следующих шагов линии действий
      this.dropSteps('credit', this.$router.currentRoute.path, '/credit/about-mortgage')

      if (value === 1) {
        this.$router.push('/credit/about-mortgage')
      } else {
        this.addMissedSteps('credit', ['/credit/about-mortgage'])
        this.$router.push({ name: 'Credit clients', params: { clients: 2 } })
      }
    }
  }
}
</script>
